import React, { lazy,useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { auth } from "./helper/firebase/iniFirebase";

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))


function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    iniAuth()
  }, [])

  function iniAuth() {
    auth().onAuthStateChanged(user => {
      if (user) {
        setIsAuthenticated(true)
        setIsLoading(false)
      } else {
        console.log("not authenticated")
        setIsAuthenticated(false)
        setIsLoading(false)
      }
    });
  }

  function SignupRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
            <Redirect to="/" />
          )
      }
    />
  );
}
function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
            <Redirect to="/accounts/login" />
          )
      }
    />
  );
}
  return (
    <>
    {!isLoading &&
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <SignupRoute
            path="/accounts/create-account"
            authenticated={isAuthenticated}
            component={CreateAccount}
          />
          <SignupRoute
            path="/accounts/login"
            authenticated={isAuthenticated}
            component={Login}
          />
          <PrivateRoute authenticated={isAuthenticated}
            path="/app" component={Layout} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}
          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/app/dashboard" />
        </Switch>
      </Router>
    }
    </>
  )
}

export default App
