//https://console.firebase.google.com/u/0/project/influxio-pod/settings/general/web:YTA5NDkyNGMtZTZkOS00NTQ0LThiYzYtYzgzYzk3NGQ5ZDMw

import firebase from 'firebase';


const config = {
  apiKey: "AIzaSyAo_qsFcyb0dh-EnSCf4nxkxQ0s-onVFCo",
  authDomain: "statik-nachweis.firebaseapp.com",
  databaseURL: "https://statik-nachweis-default-rtdb.europe-west1.firebasedatabase.app",
};

if (!firebase.apps.length) {
   firebase.initializeApp(config)
}else {
   firebase.app(); // if already initialized, use that one
}
// firebase.initializeApp(config);
export const auth = firebase.auth;
export const db = firebase.database();
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
